<template>
  <div :key="updateKey">
    <b-flix-p v-if="loading">
      <b-flix-loader></b-flix-loader>
    </b-flix-p>
    <table class="flix-form-group" v-if="logs.length && !loading">
      <thead>
        <tr>
          <th>{{ $tc('message.note', 1) }}</th>
          <th>{{ $tc('message.categories', 1) }}</th>
          <th v-if="backButton">{{ $tc('message.customer', 1) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(log, index) in logs" :key="index">
          <td>
            <div
              style="
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 5px;
              "
            >
              <bookingflix-calendar-items
                class="flix-small small"
                :settings="
                  JSON.stringify({
                    lang: $i18n.locale,
                    view: 'timer',
                    date: [log.date, log.date]
                  })
                "
              ></bookingflix-calendar-items>
              -
              <bookingflix-calendar-items
                class="flix-small small"
                :settings="
                  JSON.stringify({
                    lang: $i18n.locale,
                    view: 'date-string',
                    date: [log.date, log.date]
                  })
                "
              ></bookingflix-calendar-items>
            </div>
            <div v-html="log.msg"></div>
          </td>
          <td>{{ getCategory(log.category) }}</td>
          <td v-if="backButton">
            <a
              href="#"
              class="flix-html-a flix-html-strong"
              @click.prevent="() => setFilter(log.user)"
              >#{{ log.user }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!filter" class="flix-form-group">
      <a href="#" class="flix-btn flix-btn-default" @click.prevent="setNext">{{
        $t('message.next')
      }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loggingTable',
  components: {},
  props: {
    ID: {
      type: [String, Number, Boolean],
      default() {
        return false
      }
    },
    backButton: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      logs: [],
      totalLogs: [],
      offset: false,
      updateKey: 0,
      loading: true,
      filter: this.ID
    }
  },
  computed: {},
  mounted() {
    this.offset = 0
    if (!this.filter) {
      this.getLogs()
    } else {
      this.getSpecific()
    }
  },
  methods: {
    setFilter(user) {
      this.filter = user === this.filter ? false : user
      this.$emit('changed', this.filter)
      if (!this.filter) {
        this.logs = this.totalLogs
        this.updateKey++
        return false
      }
      this.loading = true
      this.getSpecific()
    },
    getFilter(logs) {
      if (!this.filter) {
        return logs
      }
      const newLogs = []
      logs.forEach((log) => {
        if (log.user === this.filter) {
          newLogs.push(log)
        }
      })
      return newLogs
    },
    getSpecific() {
      this.$flix_post({
        url: 'notes/get_specific',
        data: {
          user: this.$getUserVariables().user.md5_id,
          ID: this.filter,
          category: [
            'validation',
            'appointment begin',
            'checkin',
            'checkout',
            'commitment',
            'cancellation',
            'booking',
            'validation_reminder',
            'mail'
          ]
        },
        callback: (ret) => {
          if (ret.data[0]) {
            this.logs = ret.data[1]
            this.loading = false
            this.updateKey++
          }
        }
      })
    },
    getCategory(cat) {
      if (cat.includes('appointment')) {
        return this.$tc('message.reminder', 1)
      }
      if (cat.includes('booking')) {
        return this.$tc('message.appointments', 1)
      }
      if (cat.includes('checkin')) {
        return this.$tc('message.checkIn', 1)
      }
      if (cat.includes('checkout')) {
        return this.$tc('message.checkOut', 1)
      }
      if (cat.trim() === 'appointment') {
        return this.$tc('message.appointments', 1)
      }
      if (cat.trim() === 'mail') {
        return this.$tc('reminder.email', 1)
      }
      return this.$tc('message.' + cat, 1)
    },
    setNext() {
      this.loading = true
      this.offset += 1
      this.getLogs()
    },
    getLogs() {
      this.loading = true
      this.$flix_post({
        url: 'log/get',
        data: {
          user: this.$getUserVariables().user.md5_id,
          offset: this.offset
        },
        callback: (ret) => {
          if (ret.data[0]) {
            const logs = JSON.parse(JSON.stringify(this.totalLogs))

            ret.data[1].forEach((log) => {
              logs.push(log)
            })

            this.logs = this.getFilter(logs)
            this.totalLogs = logs
            this.loading = false
            this.updateKey++
          }
        }
      })
    }
  }
}
</script>
<style lang="sass" scoped>

table
  width: 100%
  tbody
    tr
      &:nth-child(even)
        background: #fff
      &:nth-child(odd)
        background: #eee
      td
        padding: 10px
</style>
