var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.updateKey},[(_vm.loading)?_c('b-flix-p',[_c('b-flix-loader')],1):_vm._e(),(_vm.logs.length && !_vm.loading)?_c('table',{staticClass:"flix-form-group"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$tc('message.note', 1)))]),_c('th',[_vm._v(_vm._s(_vm.$tc('message.categories', 1)))]),(_vm.backButton)?_c('th',[_vm._v(_vm._s(_vm.$tc('message.customer', 1)))]):_vm._e()])]),_c('tbody',_vm._l((_vm.logs),function(log,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticStyle:{"display":"flex","align-items":"center","flex-direction":"row","gap":"5px"}},[_c('bookingflix-calendar-items',{staticClass:"flix-small small",attrs:{"settings":JSON.stringify({
                  lang: _vm.$i18n.locale,
                  view: 'timer',
                  date: [log.date, log.date]
                })}}),_vm._v(" - "),_c('bookingflix-calendar-items',{staticClass:"flix-small small",attrs:{"settings":JSON.stringify({
                  lang: _vm.$i18n.locale,
                  view: 'date-string',
                  date: [log.date, log.date]
                })}})],1),_c('div',{domProps:{"innerHTML":_vm._s(log.msg)}})]),_c('td',[_vm._v(_vm._s(_vm.getCategory(log.category)))]),(_vm.backButton)?_c('td',[_c('a',{staticClass:"flix-html-a flix-html-strong",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (() => _vm.setFilter(log.user)).apply(null, arguments)}}},[_vm._v("#"+_vm._s(log.user))])]):_vm._e()])}),0)]):_vm._e(),(!_vm.filter)?_c('div',{staticClass:"flix-form-group"},[_c('a',{staticClass:"flix-btn flix-btn-default",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setNext.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('message.next')))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }